import React, { Component } from "react";

import "./Results.css";

export default class Result extends Component {
  render() {
    let all_semesters = this.props.semesters;
    let sum_total_points = null;
    let sum_total_credits = null;

    if (all_semesters.length > 1) {
      // eslint-disable-next-line
      all_semesters.map(semester => {
        sum_total_points += +semester.total_points;
        sum_total_credits += +semester.total_credits;
      });
    }

    let overall_gpa = sum_total_points / sum_total_credits;

    return (
      <div>
        {" "}
        {overall_gpa > 0 ? (
          <table className="result-table">
            <thead>
              <tr>
                <th>Semester</th>
                <th>Semester GPA</th>
              </tr>
            </thead>
            <tbody>
              {all_semesters.map(semester => {
                return (
                  <tr key={semester.id}>
                    <td>Semester {semester.id + 1}</td>
                    <td>{semester.gpa}</td>
                  </tr>
                );
              })}

              <tr>
                <td>
                  <strong>Your Overall GPA is</strong>
                </td>
                <td>
                  <strong>{overall_gpa.toFixed(2)}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        ) : null}
      </div>
    );
  }
}
