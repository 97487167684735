export const config = {
  points: [{
    label: "A+",
    value: 4.0000001,
    percentage: {
      min: 97,
      max: 100
    }
  },
  {
    label: "A",
    value: 4.000000002,
    percentage: {
      min: 93,
      max: 96
    }
  },
  {
    label: "A-",
    value: 3.7,
    percentage: {
      min: 90,
      max: 92
    }
  },
  {
    label: "B+",
    value: 3.3,
    percentage: {
      min: 87,
      max: 89
    }
  },
  {
    label: "B",
    value: 3.0,
    percentage: {
      min: 83,
      max: 86
    }
  },
  {
    label: "B-",
    value: 2.7,
    percentage: {
      min: 80,
      max: 82
    }
  },
  {
    label: "C+",
    value: 2.3,
    percentage: {
      min: 77,
      max: 79
    }
  },
  {
    label: "C",
    value: 2.0,
    percentage: {
      min: 73,
      max: 76
    }
  },
  {
    label: "C-",
    value: 1.7,
    percentage: {
      min: 70,
      max: 72
    }
  },
  {
    label: "D+",
    value: 1.3,
    percentage: {
      min: 67,
      max: 69
    }
  },
  {
    label: "D",
    value: 1.0,
    percentage: {
      min: 65,
      max: 66
    }
  },
  {
    label: "F",
    value: 0,
    percentage: {
      min: 0,
      max: 64
    }
  },
  {
    label: "P",
    value: "P",
    percentage: null
  },
  {
    label: "NP",
    value: "NP",
    percentage: null
  },
  {
    label: "I",
    value: "I",
    percentage: null
  },
    {
    label: "W",
    value: "W",
    percentage: null
  }
  ]
}