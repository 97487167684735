import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import arrow from "./images/icon_arrow-back.svg";

const NavBar = () => (
  <NavBarEl>
    <NavLinkEl className="" to="/">
      <img src={arrow} alt="" /> Home
    </NavLinkEl>
  </NavBarEl>
);

const NavBarEl = styled.div`
  position: relative;
  width: 100%;
  padding: 1rem;
  display: flex;
  max-width: 768px;
  margin: 0 auto;
`;

const NavLinkEl = styled(NavLink)`
  color: #f9931f;
  text-decoration: none;
  font-size: 17px;
  line-height: 1.3;
  display: flex;
  align-items: center;

  img {
    margin-right: 5px;
  }
`;

export default NavBar;
