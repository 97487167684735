import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import HomePage from "./pages/HomePage";
import GPACalculatorPage from "./pages/GPACalculatorPage";
import WTMConverterPage from "./pages/WTMConverterPage";
import WTPConverterPage from "./pages/WTPConverterPage";
import ThesisGeneratorPage from "./pages/ThesisGeneratorPage";

const App = () => {
  return (
    <Router>
      <Route
        render={({ location }) => {
          return (
            <>
              <TransitionGroup component={null}>
                <CSSTransition
                  timeout={300}
                  classNames="page"
                  key={location.key}
                >
                  <Switch location={location}>
                    <Route exact path="/" component={HomePage} />
                    <Route
                      exact
                      path="/gpa-calculator"
                      component={GPACalculatorPage}
                    />
                    <Route
                      exact
                      path="/words-to-minutes"
                      component={WTMConverterPage}
                    />
                    <Route
                      exact
                      path="/pages-calculation"
                      component={WTPConverterPage}
                    />
                    <Route
                      exact
                      path="/thesis-statement-generator"
                      component={ThesisGeneratorPage}
                    />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </>
          );
        }}
      />
    </Router>
  );
};

export default App;
