import React from "react";
import { NavLink } from "react-router-dom";
import Container, {
  ContainerDescription
} from "../../components/Container/Container";
import styled, { keyframes } from "styled-components";
import linkArrowIcon from "../../assets/images/icon_link-arrow.svg";
import gpaCalcIcon from "../../assets/images/icon_gpa-calculator.svg";
import wtmIcon from "../../assets/images/icon_words-to-minutes.svg";
import wtpIcon from "../../assets/images/icon_words-to-pages.svg";
import statementIcon from "../../assets/images/icon_statement-generator.svg";
import logo from "../../assets/images/logo.svg";

const HomePage = () => (
  <HomePageElm>
    <Container>
      <Logo src={logo} alt="" />
      <h1>Free online writing tools</h1>
      <ContainerDescription>
        We are happy to assist you in creating your papers
      </ContainerDescription>
    </Container>
    <HomeNav>
      <NavItem to="/gpa-calculator" icon={gpaCalcIcon}>
        GPA Calculator
      </NavItem>
      <NavItem to="/words-to-minutes" icon={wtmIcon}>
        Words to Minutes Converter
      </NavItem>
      <NavItem to="/pages-calculation" icon={wtpIcon}>
        Words to pages converter
      </NavItem>
      <NavItem to="/thesis-statement-generator" icon={statementIcon}>
        Thesis statement generator
      </NavItem>
    </HomeNav>
  </HomePageElm>
);

const slideInLeft = keyframes`
  from {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

const slideOutLeft = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
`;

const Page = styled.div`
  background-color: white;
  z-index: 3;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const Logo = styled.img`
  width: 193px;
  height: 54px;
  margin-bottom: 35px;
`;

const HomeNav = styled.div`
  margin: 10px 0 0;
  padding: 0 15px;
`;

const HomePageElm = styled(Page)`
  &.page-enter {
    animation: ${slideInLeft} 0.4s forwards;
  }
  &.page-exit {
    animation: ${slideOutLeft} 0.4s forwards;
  }
`;

const NavItem = styled(NavLink)`
  position: relative;
  padding: 1rem 1rem 1rem 1.5rem;
  width: 100%;
  text-decoration: none;
  font-size: 14px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.57;
  max-width: 768px;
  margin: 0 auto;
  background-color: #ecf5fe;

  &:not(:last-child) {
    border-bottom: 2px solid #fff;
  }

  &::after {
    content: "";
    width: 20px;
    height: 15px;
    background: url(${linkArrowIcon}) no-repeat;
    margin-left: auto;
  }

  &::before {
    content: "";
    width: 41px;
    height: 41px;
    background: url(${props => props.icon}) center no-repeat;
    margin-right: 0.75rem;
    background-size: contain;
  }
`;

export default HomePage;
