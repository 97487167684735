import React, { Component } from "react";
import PropTypes from "prop-types";
import Semester from "./Semester/Semester";
import { Button } from "react-toolbox/lib/button";
import { RadioGroup, RadioButton } from "react-toolbox/lib/radio";
import Result from "./Result/Result";
import ThemeProvider from "react-toolbox/lib/ThemeProvider";
import theme from "./toolbox/theme.js";

import "./GpaCalculator.scss";
import "./toolbox/theme.css";

export default class GpaCalculator extends Component {
  constructor() {
    super();

    this.state = {
      semesters: [
        {
          id: 0,
          gpa: undefined,
          total_points: undefined,
          total_credits: undefined
        }
      ],
      grades_type: "letter"
    };

    this.addSemester = this.addSemester.bind(this);
    this.removeSemester = this.removeSemester.bind(this);
  }

  getChildContext() {
    return {
      grades_type: this.state.grades_type
    };
  }

  changeType(grades_type) {
    this.setState({ grades_type });
  }

  addSemester() {
    let id = this.state.semesters.length;
    this.setState({ semesters: [...this.state.semesters, { id }] });
  }

  removeSemester(id) {
    this.setState(prevState => {
      return { semesters: prevState.semesters.filter(e => e.id !== id) };
    });
  }

  renderSwitcher() {
    return (
      <RadioGroup
        className="flex RadioGroup-switch"
        name={"grade_points_type_" + this.props.id}
        value={this.state.grades_type}
        onChange={e => this.changeType(e)}
      >
        <RadioButton
          className="Course-item"
          label="Letter (eg. B, A+)"
          value="letter"
        />
        <RadioButton
          className="Course-item"
          label="Percentage (eg. 75%, 67%)"
          value="percentage"
        />
      </RadioGroup>
    );
  }

  set_total_gpa(e) {
    let semesters = this.state.semesters.map(s => {
      if (s.id === e.id) {
        s.gpa = e.semester.semester_gpa;
        s.total_points = e.semester.points_sum;
        s.total_credits = e.semester.credits_sum;
      }
      return s;
    });
    this.setState({
      semesters
    });
  }

  get_results = data => {
    return <Result semesters={data} />;
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="GpaCalculator-container">
          {this.renderSwitcher()}
          {this.state.semesters.map(semester => {
            return (
              <Semester
                key={semester.id}
                id={semester.id}
                set_semester_gpa={e => this.set_total_gpa(e)}
                remove_semester={() => this.removeSemester(+semester.id)}
              />
            );
          })}

          <Button
            onClick={this.addSemester}
            icon="add"
            className="btn--add-semester"
            flat
            label="Add semester"
          />

          {this.get_results(this.state.semesters)}
        </div>
      </ThemeProvider>
    );
  }
}

GpaCalculator.childContextTypes = {
  grades_type: PropTypes.string
};
