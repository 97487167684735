import React from 'react';
import styled, { keyframes } from 'styled-components'

const PagesContainer = ({ children }) => <Page>{children}</Page>;

const slideInRight = keyframes`
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

const Page = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  
  &.page-enter {
    animation: ${slideInRight} 0.4s forwards;
  }
`;

export default PagesContainer;
