import React, { useEffect } from "react";
import styled from "styled-components";
import PagesContainer from "../../components/PagesContainer";
import NavBar from "../../components/NavBar";
import Container, {
  ContainerDescription
} from "../../components/Container/Container";
import useScript from "../../hooks/addScript";

const ThesisGeneratorPage = () => {
  const [loaded, error] = useScript({
    src: `${process.env.PUBLIC_URL}/ThesisGenerator/thesis-generator.js`
  });

  useEffect(() => {
    if (loaded && !error) {
      window.thesisGeneratorComponents = () => {
        return {
          setResultPageFooter: React => {
            return React.createElement(
              "div",
              { className: "thesis-generator-banner" },
              ""
            );
          }
        };
      };
    }

    return () => window.thesisGeneratorComponents = null;
  });

  return (
    <PagesContainer>
      <NavBar />
      <Container>
        <h1>Thesis statement generator</h1>
        <ContainerDescription>
          Create a strong thesis statement with our online tool and clearly
          express ideas in your paper.
        </ContainerDescription>
        {loaded && error ? (
          <Error>Oops! Something went wrong! :(</Error>
        ) : (
          <ThesisGenerator
            id="thesis-generator-container"
            className="thesis-generator-app"
          />
        )}
      </Container>
    </PagesContainer>
  );
};

const Error = styled.div`
  padding: 2rem 0;
`;

const ThesisGenerator = styled.div`
  margin-top: 1rem;

  form {
    padding: 0;
    background: none;
    border: 0;
  }

  input {
    border-radius: 0;
    outline: none;
    background-color: #ecf5fe;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-right: none;
    border-left: none;
    
    &:focus {
      border-top: 1px solid #f9931f;
      border-bottom: 1px solid #f9931f;
    }
  }
  
  input::placeholder {
    font-style: normal;
    background-color: #ecf5fe;
    color: #000;
  }
  
  input[data-invalid="false"] + button {
    background: #fff url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDExIDE0Ij4NCiAgICA8ZGVmcz4NCiAgICAgICAgPHN0eWxlPg0KICAgICAgICAgICAgLmNscy0xIHsgZmlsbDogI2Y5OTMxZjsgZmlsbC1ydWxlOiBldmVub2RkOyB9DQogICAgICAgIDwvc3R5bGU+DQogICAgPC9kZWZzPg0KICAgIDxwYXRoIGlkPSJkZWxldGUiIGNsYXNzPSJjbHMtMSIgZD0iTTEwLjA1IDEuNjQ4SDguMTk0Vi40MzRBLjQzMy40MzMgMCAwIDAgNy43NTMgMGEuMDY3LjA2NyAwIDAgMC0uMDQ2LjAxNi4wNTguMDU4IDAgMCAwLS4wMy0uMDE2aC00LjQzYS40MjYuNDI2IDAgMCAwLS40MzIuNDM0djEuMjE0SC45NWEuOTQuOTQgMCAwIDAtLjk1Ljk1NXYxLjU2MmguODIzdjguODlhLjkyNy45MjcgMCAwIDAgLjk0Ljk0NWg3LjQ3M2EuOTM0LjkzNCAwIDAgMCAuOTUtLjk0NnYtOC44OUgxMVYyLjZhLjk0Ljk0IDAgMCAwLS45NS0uOTUyek0zLjY3OC44NjhoMy42NDZ2Ljc4SDMuNjc3di0uNzh6bTUuNjM3IDEyLjE4NmEuMDY3LjA2NyAwIDAgMS0uMDc4LjA3OEgxLjc2NGEuMDY3LjA2NyAwIDAgMS0uMDc4LS4wNzh2LTguODloNy42Mjh2OC44OXptLjgxNi05Ljc1N0guODdWMi42YS4wNzQuMDc0IDAgMCAxIC4wOC0uMDg3aDkuMWEuMDc0LjA3NCAwIDAgMSAuMDc4LjA4N3YuN3pNNy4wOCA1LjE4MmguODd2Ny4xNzdoLS44N1Y1LjE4em0tMi4wMDcgMGguODd2Ny4xNzdoLS44N1Y1LjE4em0tMi4wMDcgMGguODd2Ny4xNzdoLS44N1Y1LjE4eiIvPg0KPC9zdmc+DQo=) 50%/11px no-repeat !important;
  }
  
  button {
    cursor: pointer;
    font-size: 17px;
    outline: none;
    position: relative;
    display: inline;
    border: 0;
    
    &[type="submit"] {
      width: 100%;
      padding: 9px 35px 9px 15px;
      color: #000;
      background-color: #f9931f;
      border-radius: 6px;
      text-transform: lowercase;
      max-width: 420px;
      margin: 0 auto;
    }

    &[type="button"] {
      color: #f9931f;
      text-align: left;
      margin: 0;
      font-weight: bold;
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }
`;

export default ThesisGeneratorPage;
