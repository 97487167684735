import React, {Component} from 'react';
import Input from 'react-toolbox/lib/input/Input';
import IconButton from 'react-toolbox/lib/button/IconButton';
import Dropdown from 'react-toolbox/lib/dropdown';
import PropTypes from 'prop-types';
import './Course.scss';
import {config} from '../config';

export default class Course extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id,
      course_name: '',
      points: config.points,
      credits: '',
      grade_points: '',
      grade_percentage: '',
      grade_val: '',
      errors: {
        'grade_percentage': {
          status: false,
          err_text: 'Error value > 100 (perc)'
        },
        'credits': {
          status: false,
          err_text: 'Error credits > 100'
        }
      }
    }
  }

  convertPoints(e) {
    let converted_val = 0;
    e = Number(e);

    for (let point in this.state.points) {
      let p = this.state.points[point];
      if (p.percentage !== null && p.percentage !== undefined) {
        if (e <= p.percentage.max && e >= p.percentage.min) {
          converted_val = p.value
        }
      }
    }

    return converted_val
  }

  calc_total_values() {
    if (this.state.credits !== '' && (this.state.grade_points !== '' || this.state.grade_percentage !== '')) {
      let calc_grade_val = this.context.grades_type === 'percentage' ? this.convertPoints(this.state.grade_percentage) : Number(this.state.grade_points.toFixed(1));
      this.setState({
        total_points: Number(this.state.credits) * Number(calc_grade_val)
      }, () => {
        this.props.set_val(this.state)
      });
    }
  }

  get_key = e => {
    if (e.ctrlKey || e.altKey || e.metaKey || e.shiftKey) return;
    if (e.key < 0 || e.key > 9 || e.key === '+' || e.key === '-' || e.key === 'e' || e.key === 'E') {
      e.preventDefault();
    }
  };

  set_value = (e, name) => {
    if (name === 'grade_percentage') {
      this.validate_input(e, name);
      e = this.state.errors[name].status ? 100 : e;
    }

    this.setState({
      [name]: e
    }, () => {
      this.calc_total_values(e);
    });
  };

  change_course_name(course_name) {
    this.setState({course_name});
  }

  validate_input(val, name, max = 100) {
    let value = !isNaN(parseFloat(val)) && isFinite(val) ? val : 0;
    let errors_arr = this.state.errors;
    errors_arr[name].status = value > max;

    this.setState({
      errors: errors_arr
    })
  }

  renderSelectGrade() {
    return (
      <Dropdown
        auto
        label="Grade"
        className="Course-item Course-item-points Dropdown-item"
        required={true}
        value={this.state.grade_points}
        onChange={e => this.set_value(e, 'grade_points')}
        source={config.points}
      />
    )
  }

  renderInputGrade() {
    return (
      <Input
        label="Grade (%)"
        className="Course-item Course-item-percentage"
        type={"number"}
        required={true}
        value={typeof +this.state.grade_percentage === 'number' && this.state.grade_percentage > 0 ? this.state.grade_percentage : ''}
        onChange={e => this.set_value(e, 'grade_percentage')}
        onKeyPress={e => this.get_key(e)}
        onKeyUp={e => this.get_key(e, 'grade_percentage')}
      />
    )
  }

  render() {
    return (
      <div className="Course-group">
        <Input
          label="Course name"
          className="Course-item Course-item-name"
          onChange={e => this.change_course_name(e)}
          value={this.state.course_name}
        />

        {this.context.grades_type === 'letter' ? this.renderSelectGrade() : this.renderInputGrade()}

        <Input
          label="Credits"
          ref={e => this.credits_input = e}
          className="Course-item Course-item-credits"
          type={"number"}
          floating={true}
          required={true}
          onChange={e => this.set_value(e, 'credits')}
          onKeyPress={e => this.get_key(e)}
          value={typeof +this.state.credits === 'number' && this.state.credits > 0 && !isNaN(parseFloat(this.state.credits)) ? this.state.credits : ''}
        />

        {
          this.props.id > 1 &&
          <IconButton className="Course-item--remove btn-remove btn-icon" icon="delete" onClick={() => {
            this.props.removeCourse(this.props.id)
          }}/>
        }
      </div>
    );
  }
}

Course.propTypes = {
  id: PropTypes.number,
  removeCourse: PropTypes.func
};

Course.contextTypes = {
  grades_type: PropTypes.string
}
