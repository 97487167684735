import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Semester.scss";
import Button from "react-toolbox/lib/button/Button";
import Course from "../Course/Course";

export default class Semester extends Component {
  constructor(props) {
    super(props);

    this.set_val = this.set_val.bind(this);

    this.state = {
      courses: [
        {
          id: 0,
          credits: 0,
          grade_val: 0,
          total_points: 0
        },
        {
          id: 1,
          credits: 0,
          grade_val: 0,
          total_points: 0
        },
        {
          id: 2,
          credits: 0,
          grade_val: 0,
          total_points: 0
        }
      ],
      points_sum: 0,
      credits_sum: undefined
    };
  }

  calc_total_points() {
    const _courses = this.state.courses;
    let points_sum = null;
    let credits_sum = null;

    for (let i = 0; i < _courses.length; i++) {
      if (_courses[i].credits && _courses[i].total_points >= 0) {
        credits_sum += +_courses[i].credits;
        points_sum += _courses[i].total_points;
      }
    }

    if (points_sum !== null && credits_sum !== null) {
      let semester_gpa = (points_sum / credits_sum).toFixed(2);
      this.setState(
        {
          points_sum,
          credits_sum,
          semester_gpa
        },
        () => {
          this.props.set_semester_gpa({
            semester: this.state,
            id: this.props.id
          });
        }
      );
    } else if (points_sum === null) {
      this.setState(
        {
          semester_gpa: "0.00"
        },
        () => {
          this.props.set_semester_gpa({
            semester: this.state,
            id: this.props.id
          });
        }
      );
    }
  }

  addCourse() {
    let id =
      Math.max.apply(
        Math,
        this.state.courses.map(e => e.id)
      ) + 1;
    this.setState({
      courses: [...this.state.courses, { id, total_points: 0 }]
    });
  }

  removeCourse(id) {
    if (id > 1) {
      const _courses = this.state.courses;
      let del_id = this.state.courses.map(e => e.id).indexOf(id);
      _courses.splice(del_id, 1);
      this.setState(
        {
          courses: _courses
        },
        () => {
          this.calc_total_points();
        }
      );
    }
  }

  set_val(e) {
    let courses = this.state.courses;

    courses.map(course => {
      if (course.id === e.id) {
        Object.assign(course, e);
      }
      return course;
    });

    this.setState(
      {
        courses
      },
      () => {
        this.calc_total_points();
      }
    );
  }

  render() {
    return (
      <div
        className={
          this.props.id === 0
            ? "Semester-container Semester-first-container"
            : "Semester-container"
        }
      >
        <div className="Semester-heading">
          <strong>Semester {this.props.id + 1}</strong>{" "}
          {this.props.id > 0 ? (
            <Button
              type="button"
              className="Semester-btn-remove btn-remove"
              onClick={() => this.props.remove_semester(this.props.id)}
              label=""
              flat
              icon="delete"
            />
          ) : null}
          {this.state.semester_gpa !== undefined &&
            ((
              <div className="Semester-gpa-result">
                <strong>GPA</strong> is{" "}
                <strong>{this.state.semester_gpa}</strong>
              </div>
            ) ||
              "")}
        </div>
        <div className="Semester-body">
          {this.state.courses.map(course => {
            return (
              <Course
                key={course.id}
                id={course.id}
                removeCourse={() => this.removeCourse(course.id)}
                set_val={e => this.set_val(e)}
              />
            );
          })}
        </div>

        <div className="Semester-footer">
          <Button
            type="button"
            className="Semester-btn-addCourse btn-primary"
            onClick={() => this.addCourse()}
            label="Add course"
            flat
            icon="add"
          />
        </div>
      </div>
    );
  }
}

Semester.propTypes = {
  id: PropTypes.number,
  set_semester_gpa: PropTypes.func,
  remove_semester: PropTypes.func
};
