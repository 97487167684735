import React from "react";
import PagesContainer from "../../components/PagesContainer";
import NavBar from "../../components/NavBar";
import Container, {
  ContainerDescription
} from "../../components/Container/Container";
import GpaCalculator from "../../components/Tools/GPACalculator";

const GPACalculatorPage = () => (
  <PagesContainer>
    <NavBar />
    <Container>
      <h1>College GPA calculator</h1>
      <ContainerDescription>
        Want to know how well you’ve performed this semester? Our tool will help
        you calculate your semester GPA.
      </ContainerDescription>
    </Container>
    <GpaCalculator />
  </PagesContainer>
);

export default GPACalculatorPage;
