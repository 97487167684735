import {
  useState,
  useEffect,
} from 'react';


const useScriptLoader = ({
  src,
  async = true,
  appendTo = document.head,
}) => {
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  useEffect(
    () => { /* eslint-disable-line */
      if (document.querySelector(`script[data-src="${src}"]`)) {
        setState({
          loaded: true,
          error: false,
        });
      } else {
        // Create script
        const script = document.createElement('script');
        script.src = src;
        script.setAttribute('data-src', src);
        script.async = async;

        // Script event listener callbacks for load
        const onScriptLoad = () => {
          setState({
            loaded: true,
            error: false,
          });
        };

        // Script event listener callbacks for error
        const onScriptError = () => {
          setState({
            loaded: true,
            error: true,
          });
        };

        script.addEventListener('load', onScriptLoad, false);
        script.addEventListener('error', onScriptError, false);

        // Add script to document
        appendTo.appendChild(script);

        // Remove event listeners on cleanup (unMount)
        return () => {
          script.removeEventListener('load', onScriptLoad, false);
          script.removeEventListener('error', onScriptError, false);
          script.parentNode.removeChild(script);
        };
      }
    },
    [src], /* eslint-disable-line */ // Only re-run effect if src prop changes
  );

  return [
    state.loaded,
    state.error,
  ];
};

export default useScriptLoader;
