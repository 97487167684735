import React from "react";
import styled from "styled-components";

const Container = ({ children }) => <ContainerEl>{children}</ContainerEl>;

const ContainerEl = styled.div`
  padding: 1rem;
  max-width: 768px;
  margin: 0 auto;

  h1 {
    margin: 0 0 10px;
    text-align: left;
    font-size: 28px;
    line-height: 1.3;
    color: black;
  }
`;

export const ContainerDescription = styled.p`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 17px;
  line-height: 1.3;
  letter-spacing: -0.4px;
  color: black;
`;

export default Container;
